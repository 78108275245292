<template>
  <div>
    <v-card class="ma-2" :height="height">
      <v-card-title class="pa-3">
        <v-btn tile outlined @click="$router.go(-1)">
          <v-icon size="17" left> mdi-arrow-left </v-icon>
          <span> Back </span>
        </v-btn>
        <v-spacer></v-spacer>
        <span class="body-1 font-weight-bold grey--text text--darken-2 mb-n1"> {{ (modelObj.courseId?.name || modelObj.name) }} - {{ modelObj.section }} </span>
        <v-spacer></v-spacer>
        <v-btn tile color="success" class="mx-2" @click="updateHandler"> Update </v-btn>
        <v-btn tile color="error" @click="deleteHandler"> Delete </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pa-0">
        <v-card flat>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <span class="body-2 font-weight-bold grey--text text--darken-2"> Manage subjects </span>
                <div>
                  <v-simple-table
                    fixed-header
                    height="25vh"
                    class="px-4 py-1"
                    dense
                  >
                    <template #default>
                      <thead>
                        <tr>
                          <td class="caption font-weight-bold grey--text text--darken-3" width="25%"> Subject </td>
                          <td class="caption font-weight-bold grey--text text--darken-3" width="25%"> Staff Incharge(s) </td>
                          <td class="caption font-weight-bold grey--text text--darken-3" width="25%"> Applicable students </td>
                          <td class="caption font-weight-bold grey--text text--darken-3" width="25%"> Actions </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="({ subjectId: subjectItem }, subjectIndex) in modelObj.subjects" :key="subjectIndex"
                        >
                          <td>
                            <span class="font-weight-medium caption grey--text text--darken-3"> {{ subjectItem.name }} - {{ subjectItem.code }} </span>
                          </td>
                          <td>
                            <!-- <span class="font-weight-medium caption"> {{ subjectItem.staffIds }} </span> -->
                            <v-autocomplete
                              v-model="subjectItem.staffIds" :items="staffList" item-text="formdata.name" item-value="_id"
                              hide-details dense outlined style="max-width: 14vw;"
                            ></v-autocomplete>
                          </td>
                          <td>
                            <!-- <span class="font-weight-medium caption"> {{ subjectItem.studentIds }} </span> -->
                            <v-autocomplete
                              v-model="subjectItem.studentIds" :items="studentList" item-text="formdata.name" item-value="_id"
                              hide-details dense outlined style="max-width: 14vw;"
                            ></v-autocomplete>
                          </td>
                          <td>
                            <div>
                              <v-btn icon small color="error">
                                <v-icon small> mdi-minus-circle </v-icon>
                              </v-btn>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <span class="body-2 font-weight-bold grey--text text--darken-2"> Manage Routine </span>
                <div class="py-1">
                  <full-calendar ref="fullCalendar" :options="calendarOptions"></full-calendar>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import FullCalendar from "@fullcalendar/vue"
import resourceTimelinePlugin from "@fullcalendar/resource-timeline"
import interactionPlugin from "@fullcalendar/interaction"
export default {
  components: {
    'full-calendar': FullCalendar
  },
  data () {
    return {
      height: window.innerHeight - 78,
      classId: this.$route.params.id,
      modelObj: {},
      studentList: [],
      staffList: [],
      calendarOptions: {
        schedulerLicenseKey: '0495968672-fcs-1642855266',
        headerToolbar: false,
        height: 'auto',
        resourceAreaWidth: '14%',
        initialDate: this.$moment().format('YYYY-MM-DD'),
        eventMaxStack: 2,
        plugins: [resourceTimelinePlugin, interactionPlugin],
        resourceAreaHeaderContent: 'Weekdays',
        initialView: 'resourceTimeline',
        views: {
          resourceTimeline: {
            slotDuration: '00:15'
          },
          customView: {
            type: 'resourceTimelineWeek',
            hiddenDays: [0] // Hide Sunday
          }
        },
        resources: [],
        events: []
      }
    }
  },
  beforeMount () {
    this.getSingleRecordHandler()
    const resources = [...this.weekDays].map((day) => ({
      ...day,
      id: day.value,
      title: day.name,
    }))
    this.$set(this.calendarOptions, 'resources', resources)
  },
  mounted () {
    window.onresize = () => {
      this.height = window.innerHeight - 78
    }
  },
  methods: {
    getSingleRecordHandler () {
      this.$_execute('get', `/classes/${this.classId}`)
        .then(({ data } = {}) => {
          this.modelObj = data
          this.getClassStudents()
          // if (!this.modelObj.routines || !this.modelObj.routines.length) this.modelObj.routines = []
          // this.getClassRoutines()
          // this.modelObj.routines.map(x => {
          //   x.subject = this.listOfSubjects.find(y => y._id === x.subjectid)
          //   x.staff = this.listOfStaffs.find(y => y._id === x.staffid)
          // })
        })
        .catch(() => {
          this.$router.push('/academic/classrooms')
        })
    },
    getClassStudents () {
      this.$_execute('get', `/classes/${this.classId}/get-students`)
        .then(({ data } = {}) => {
          data = data.map((item) => item.studentId)
          this.studentList = data
        })
    },
    getClassRoutines () {
      this.$_execute('get', `classes/get-routines?class=${this.classId}`).then(({ data }) => {
        const routines = data.map((routineItem) => ({
          ...routineItem,
          resourceId: routineItem.weekday,
          title: routineItem.isbreak ? routineItem.title : [routineItem?.subject?.name].filter(x => x).join(' - '),
          start: this.$_formatDate(routineItem.startat, 'HH:mm', 'YYYY-MM-DDTHH:mm'),
          end: this.$_formatDate(routineItem.endat, 'HH:mm', 'YYYY-MM-DDTHH:mm'),
        }))
        this.$set(this.calendarOptions, 'events', routines)
      })
    },
    updateHandler () {
      console.log('update handler')
      // this.$_execute(`put`, `/classes/${this.modelObj._id}`, this.modelObj)
      //   .then(() => {
      //     this.modelObj.routines.map((x) => {
      //       x.subject = this.listOfSubjects.find(y => y._id === x.subjectid)
      //       x.staff = this.listOfStaffs.find(y => y._id === x.staffid)
      //     })
      //     this.$root.$emit('snackbar', { message: 'Updated Successfully', color: 'success'})
      //   })
    }, 
    deleteHandler () {
      this.$root.$emit('delete', {
        module: 'classes',
        _id: this.modelObj._id,
        onResolve: () => {
          this.$root.$emit('snackbar', { message: `Deleted Successfully`, color: 'success' })
          this.$router.push('/academic/classrooms')
        }
      })
    }
  }
}
</script>

<style>
  #timetable {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  #timetable td, #timetable th {
    border: 1px solid #ddd;
    padding: 8px;
  }

  #timetable tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  #timetable tr td:nth-child(even):hover {
    background-color: #ddd;
  }
  .schedule-planner .fc-license-message {
    display: none !important;
  }
  .schedule-planner table tr div.fc-scroller {
    overflow: auto !important;
  }
  .schedule-planner * {
    color: rgb(99, 99, 99) !important;
  }
</style>